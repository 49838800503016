<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">
              <a-form-item label="话题id commuity_topic.id" prop="topicId">
                <a-input v-model="queryParam.topicId" placeholder="请输入话题id commuity_topic.id" allow-clear/>
              </a-form-item>
            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="评价类型" prop="type">
                <a-select placeholder="请选择评价类型" style="width: 100%" v-model="queryParam.type" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TrendsEnum" :value="item.type" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="用户" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用户名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">
                <a-form-item label="用户id 外键user_user.id" prop="userId">
                  <a-input v-model="queryParam.userId" placeholder="请输入用户id 外键user_user.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="ip地址" prop="ip">
                  <a-input v-model="queryParam.ip" placeholder="请输入ip地址" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="城市" prop="city">
                  <a-input v-model="queryParam.city" placeholder="请输入城市" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否剧透 1:是 0:否" prop="isSpoiler">
                  <a-input v-model="queryParam.isSpoiler" placeholder="请输入是否剧透 1:是 0:否" allow-clear/>
                </a-form-item>
              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="是否下架" prop="isOff">
                  <a-select placeholder="请选择是否下架" style="width: 100%" v-model="queryParam.isOff" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否折叠" prop="isFold">
                  <a-select placeholder="请选择是否下架" style="width: 100%" v-model="queryParam.isFold" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否种草" prop="isRecommendSage">
                  <a-select placeholder="请选择是否种草" style="width: 100%" v-model="queryParam.isRecommendSage" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否推荐" prop="isRecommend">
                  <a-select placeholder="请选择是否推荐" style="width: 100%" v-model="queryParam.isRecommend" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否置顶" prop="isTop">
                  <a-select placeholder="请选择是否置顶" style="width: 100%" v-model="queryParam.isTop" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否热门" prop="isHot">
                  <a-select placeholder="请选择是否热门" style="width: 100%" v-model="queryParam.isHot" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="8" :sm="24">
                <a-form-item label="修改次数" prop="modifyCount">
                  <a-input v-model="queryParam.modifyCount" placeholder="请输入修改次数" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺id" prop="shopId">
                  <a-input v-model="queryParam.shopId" placeholder="请输入店铺id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="展会id" prop="expoId">
                  <a-input v-model="queryParam.expoId" placeholder="请输入展会id" allow-clear/>
                </a-form-item>
              </a-col>-->
<!--              <a-col :md="8" :sm="24">
                <a-form-item label="剧本id 外键script_script.id" prop="scriptId">
                  <a-input v-model="queryParam.scriptId" placeholder="请输入剧本id 外键script_script.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="扮演角色" prop="scriptRole">
                  <a-input v-model="queryParam.scriptRole" placeholder="请输入扮演角色" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="点赞数" prop="likeNum">
                  <a-input v-model="queryParam.likeNum" placeholder="请输入点赞数" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="评论数" prop="commentNum">
                  <a-input v-model="queryParam.commentNum" placeholder="请输入评论数" allow-clear/>
                </a-form-item>
              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['community:article:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['community:article:edit']">
          <a-icon type="edit" />修改
        </a-button>-->
        <a-button type="primary" @click="$refs.BatchDramaReviewsPersonRef.openModal()" v-hasPermi="['community:article:add']">
          <a-icon type="plus" />批量剧评新增
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['community:article:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['community:article:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 手风琴 用户列表 -->
      <BatchDramaReviewsPerson ref="BatchDramaReviewsPersonRef" />
<!--      <BatchDramaReviewsAddDetail-->
<!--        ref="batchDramaReviewsAddRef"-->
<!--        :evaluationList="personAllData[0].evaluationList"-->
<!--        @ok="getList"-->
<!--      />-->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['community:article:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['community:article:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['community:article:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['community:article:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
        <span slot="type" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TrendsEnum" :value="record.type"/>
        </span>
        <span slot="isSpoiler" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isSpoiler"/>
        </span>
        <span slot="isOff" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isOff"/>
        </span>
        <span slot="isFold" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isFold"/>
        </span>
        <span slot="isRecommendSage" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isRecommendSage"/>
        </span>
        <span slot="isRecommend" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isRecommend"/>
        </span>
        <span slot="isTop" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isTop"/>
        </span>
<!--        <template slot="img" slot-scope="text, record">-->
<!--          <div>-->
<!--            <img v-for="imgurl in record.img.split(',')"-->
<!--                 v-if="record.img" :src="imgurl"  style="width:60px;height:62px;" />-->
<!--          </div>-->
<!--        </template>-->
        <span slot="isHot" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isHot"/>
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listArticle, delArticle } from '@/api/community/article'
import CreateForm from './modules/CreateForm'
import BatchDramaReviewsPerson from './modules/BatchDramaReviewsPerson.vue'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import CustomDictTag from '@/components/DictCustomTag'
export default {
  name: 'Article',
  components: {
    CreateForm,
    BatchDramaReviewsPerson,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      personAllData: [
        {
          userId: 398769922731282432,
          userName: '测试专用_2999',
          evaluationList: []
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        topicId: null,
        userName: null,
        content: null,
        img: null,
        userId: null,
        ip: null,
        city: null,
        isSpoiler: null,
        isOff: null,
        isFold: null,
        isRecommendSage: null,
        is_recommend: null,
        nickName: null,
        isTop: null,
        isHot: null,
        modifyCount: null,
        shopId: null,
        expoId: null,
        scriptId: null,
        scriptRole: null,
        evaluation: null,
        likeNum: null,
        reply:null,
        replyTime:null,
        commentNum: null,
        shopEvaluation: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '话题id commuity_topic.id',
          dataIndex: 'topicId',
          ellipsis: true,
          align: 'center'
        },*/

        // {
        //   title: '图片',
        //   dataIndex: 'img',
        //   scopedSlots: { customRender: 'img' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户',
          dataIndex: 'nickName',
          width: 170,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
          align: 'center'
        },
        {
          title: '关联名称',
          dataIndex: 'relationName',
          minWidth: 200,
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '内容',
        //   dataIndex: 'content',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: 'ip地址',
        //   dataIndex: 'ip',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '城市',
        //   dataIndex: 'city',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '是否剧透',
          dataIndex: 'isSpoiler',
          scopedSlots: { customRender: 'isSpoiler' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否下架',
        //   dataIndex: 'isOff',
        //   scopedSlots: { customRender: 'isOff' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '是否折叠',
          dataIndex: 'isFold',
          scopedSlots: { customRender: 'isFold' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否种草',
          dataIndex: 'isRecommendSage',
          scopedSlots: { customRender: 'isRecommendSage' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否推荐',
          dataIndex: 'isRecommend',
          scopedSlots: { customRender: 'isRecommend' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否置顶',
          dataIndex: 'isTop',
          scopedSlots: { customRender: 'isTop' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否热门',
          dataIndex: 'isHot',
          scopedSlots: { customRender: 'isHot' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '店家回复',
        //   dataIndex: 'reply',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '店家回复时间',
        //   dataIndex: 'replyTime',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: 170,
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: { ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询星河村-动态文章列表 */
    getList () {
      this.loading = true
      listArticle(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        topicId: undefined,
        userName: null,
        content: undefined,
        img: undefined,
        userId: undefined,
        ip: undefined,
        city: undefined,
        isSpoiler: undefined,
        isOff: undefined,
        isFold:undefined,
        isRecommendSage: undefined,
        is_recommend:undefined,
        nickName: undefined,
        isTop: undefined,
        isHot: undefined,
        modifyCount: undefined,
        shopId: undefined,
        expoId: undefined,
        scriptId: undefined,
        scriptRole: undefined,
        evaluation: undefined,
        likeNum: undefined,
        reply:undefined,
        replyTime:undefined,
        commentNum: undefined,
        shopEvaluation: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delArticle(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('community/article/export', {
            ...that.queryParam
          }, `article_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
