<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="评价类型" prop="type">
        <a-select style="width: 100%" v-model="form.type" placeholder="请输入评价类型" disabled>
          <a-select-option v-for="(item, index) in this.customDict.TrendsEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!--      <a-form-model-item label="图片" prop="img" >
              <a-upload
                disabled
                name="img"
                listType="picture-card"
                class="avatar-uploader"
                :multiple="true"
                :show-upload-list="false"
                :preview="handlePreview"
                :before-upload="beforeOssUpload"
                :customRequest="imgAvatarUpload">
                <img
                  v-if="form.img"
                  :src="form.img"
                  alt="img"
                  style="height: 102px; width: 102px; border-radius: 50%"/>
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'"/>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>-->
      <a-form-model-item label="图片" prop="图片">
        <a-upload
          disabled
          name="图片"
          v-model="fileList"
          listType="picture-card"
          :fileList="fileList"
          :remove="removeFile"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="true"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="视频" prop="video">
        <oss-multi-upload v-model="form.video" type="video" :maxSize="200"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="用户" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入用户" disabled/>
      </a-form-model-item>
      <a-form-model-item label="剧本评价" prop="officialEvaluation">
        <template>
          <a-card>
            <a-form-model-item label="推荐等级" prop="evaluation.level">
              <a-select style="width: 100%" v-model="form.evaluation.level" placeholder="请输入标签" disabled>
                <a-select-option v-for="(item, index) in this.customDict.LeaveTypeEnum" :value="item.type" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="故事性评分" prop="evaluation.scoreStore">
              <a-input disabled v-model="form.evaluation.scoreStore" placeholder="请输入内容" type="textarea" allow-clear/>
            </a-form-model-item>
            <a-form-model-item label="体验感评分" prop="evaluation.scoreExperience">
              <a-input disabled v-model="form.evaluation.scoreExperience" placeholder="请输入内容" type="textarea" allow-clear/>
            </a-form-model-item>
            <a-form-model-item label="可玩性评分" prop="evaluation.scorePlay">
              <a-input disabled  v-model="form.evaluation.scorePlay" placeholder="请输入内容" type="textarea" allow-clear/>
            </a-form-model-item>
          </a-card>
        </template>
      </a-form-model-item>
<!--      <a-form-model-item label="内容" prop="content">-->
<!--        <editor v-model="form.content"/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="内容" prop="content" >
        <a-input disabled v-model="form.content" placeholder="请输入内容" type="textarea"allow-clear/>
      </a-form-model-item>
      <!--      <a-form-model-item label="话题id commuity_topic.id" prop="topicId" >
              <a-input v-model="form.topicId" placeholder="请输入话题id commuity_topic.id" />
            </a-form-model-item>-->
      <!--      <a-form-model-item label="内容" prop="content" >
              <editor v-model="form.content" />
            </a-form-model-item>

            <a-form-model-item label="用户id 外键user_user.id" prop="userId" >
              <a-input v-model="form.userId" placeholder="请输入用户id 外键user_user.id" />
            </a-form-model-item>
            <a-form-model-item label="ip地址" prop="ip" >
              <a-input v-model="form.ip" placeholder="请输入ip地址" />
            </a-form-model-item>
            <a-form-model-item label="城市" prop="city" >
              <a-input v-model="form.city" placeholder="请输入城市" />
            </a-form-model-item>
            <a-form-model-item label="是否剧透 1:是 0:否" prop="isSpoiler" >
              <a-input v-model="form.isSpoiler" placeholder="请输入是否剧透 1:是 0:否" />
            </a-form-model-item>-->

      <a-form-model-item label="是否下架" prop="isOff">
        <a-switch v-model="form.isOff" />{{ form.isOff ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否折叠" prop="isFold">
        <a-switch v-model="form.isFold" />{{ form.isFold ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否种草" prop="isRecommendSage">
        <a-switch v-model="form.isRecommendSage" />{{ form.isRecommendSage ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否推荐" prop="isRecommend">
        <a-switch v-model="form.isRecommend" />{{ form.isRecommend ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否置顶" prop="isTop">
        <a-switch v-model="form.isTop" />{{ form.isTop ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否热门" prop="isHot">
        <a-switch v-model="form.isHot" />{{ form.isHot ? '是' : '否' }}
      </a-form-model-item>
      <!--      <a-form-model-item label="修改次数" prop="modifyCount" >
              <a-input v-model="form.modifyCount" placeholder="请输入修改次数" />
            </a-form-model-item>
            <a-form-model-item label="店铺id" prop="shopId" >
              <a-input v-model="form.shopId" placeholder="请输入店铺id" />
            </a-form-model-item>
            <a-form-model-item label="展会id" prop="expoId" >
              <a-input v-model="form.expoId" placeholder="请输入展会id" />
            </a-form-model-item>
            <a-form-model-item label="剧本id 外键script_script.id" prop="scriptId" >
              <a-input v-model="form.scriptId" placeholder="请输入剧本id 外键script_script.id" />
            </a-form-model-item>
            <a-form-model-item label="扮演角色" prop="scriptRole" >
              <a-input v-model="form.scriptRole" placeholder="请输入扮演角色" />
            </a-form-model-item>-->
      <!--      <a-form-model-item label="评分 json {"level":"推荐等级","score_store":"故事性评分","score_play":"可玩性评分", "score_experience" "体验感评分"}" prop="evaluation" >
            </a-form-model-item>
            <a-form-model-item label="点赞数" prop="likeNum" >
              <a-input v-model="form.likeNum" placeholder="请输入点赞数" />
            </a-form-model-item>
            <a-form-model-item label="评论数" prop="commentNum" >
              <a-input v-model="form.commentNum" placeholder="请输入评论数" />
            </a-form-model-item>
            <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
            </a-form-model-item>-->
<!--      <a-form-model-item label="店家回复" prop="reply" >-->
<!--        <a-input  v-model="form.reply" placeholder="请输入店家回复" allow-clear/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>
      <!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
              <a-input v-model="form.version" placeholder="请输入乐观锁" />
            </a-form-model-item>
            <a-form-model-item label="评分 json {"serve":"服务","DM":"DM评分","environment":"环境评分"}" prop="shopEvaluation" >
            </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getArticle, addArticle, updateArticle} from '@/api/community/article'
import Editor from '@/components/Editor'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from '@/api/tool/alioss'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
export default {
  name: 'CreateForm',
  props: {},
  components: {
    Editor,OssMultiUpload
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      formTitle: '',
      fileList: [],
      // 表单参数
      form: {
        id: null,

        type: null,

        topicId: null,

        content: null,
        reply:null,
        replyTime:null,

        img: null,

        userId: null,

        ip: null,

        city: null,

        nickName: null,
        video:null,
        isSpoiler: null,

        isOff: null,
        isFold:null,
        isTop: null,

        isHot: null,

        modifyCount: null,

        shopId: null,

        expoId: null,

        scriptId: null,

        scriptRole: null,

        evaluation: {
          level: null,
          levelName: null,
          scoreExperience: null,
          scorePlay: null,
          scoreStore: null
        },

        likeNum: null,

        commentNum: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        shopEvaluation: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          {required: true, message: '类型 1:动态,2:剧评,3:展评,4:店评不能为空', trigger: 'change'}
        ],

        topicId: [
          {required: true, message: '话题id commuity_topic.id不能为空', trigger: 'blur'}
        ],

        content: [
          {required: true, message: '内容不能为空', trigger: 'blur'}
        ],

        userId: [
          {required: true, message: '用户id 外键user_user.id不能为空', trigger: 'blur'}
        ],

        ip: [
          {required: true, message: 'ip地址不能为空', trigger: 'blur'}
        ],

        city: [
          {required: true, message: '城市不能为空', trigger: 'blur'}
        ],

        isSpoiler: [
          {required: true, message: '是否剧透 1:是 0:否不能为空', trigger: 'blur'}
        ],

        isOff: [
          {required: true, message: '是否下架 1:是 0:否不能为空', trigger: 'blur'}
        ],

        isTop: [
          {required: true, message: '是否置顶 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isHot: [
          {required: true, message: '是否热门 1:是,0:否不能为空', trigger: 'blur'}
        ],

        modifyCount: [
          {required: true, message: '修改次数不能为空', trigger: 'blur'}
        ],

        shopId: [
          {required: true, message: '店铺id不能为空', trigger: 'blur'}
        ],

        expoId: [
          {required: true, message: '展会id不能为空', trigger: 'blur'}
        ],

        scriptId: [
          {required: true, message: '剧本id 外键script_script.id不能为空', trigger: 'blur'}
        ],

        scriptRole: [
          {required: true, message: '扮演角色不能为空', trigger: 'blur'}
        ],

        likeNum: [
          {required: true, message: '点赞数不能为空', trigger: 'blur'}
        ],

        commentNum: [
          {required: true, message: '评论数不能为空', trigger: 'blur'}
        ],

        isDeleted: [
          {required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur'}
        ],

        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.fileList = []
      this.form = {
        id: null,

        type: null,

        topicId: null,

        content: null,
        reply:null,
        replyTime:null,
        img: null,

        userId: null,

        ip: null,

        city: null,

        nickName: null,
        video:null,
        isSpoiler: null,

        isOff: null,
        isFold:null,
        isTop: null,

        isHot: null,

        modifyCount: null,

        shopId: null,

        expoId: null,

        scriptId: null,

        scriptRole: null,

        evaluation: {
          level: null,
          levelName: null,
          scoreExperience: null,
          scorePlay: null,
          scoreStore: null
        },

        likeNum: null,

        commentNum: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        shopEvaluation: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getArticle(id).then(response => {
        this.form = response.data
        this.form.evaluation = JSON.parse(this.form.evaluation)
        this.open = true
        this.formTitle = '修改'
        // 处理图片反显
        if (response.data.img !== undefined && response.data.img !== null && JSON.stringify(response.data.img) !== '"{}"') {
          var images = this.form.img.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'img.jpg'})
          }
        }
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cover'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'img', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },

    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'img'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'img.jpg'})
          var info = ''
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'img', info)
          console.log('info=', _this.form.img)
          _this.$message.success('上传成功')
        })
      })
    },
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      this.fileList = fileList
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.evaluation = JSON.stringify(this.form.evaluation)
          if (this.form.id !== undefined && this.form.id !== null) {
            updateArticle(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addArticle(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
